/**
 *
 * Locale Page
 * 
*/

import React, { Fragment } from "react"

import BasePage from "./BasePage"
import Module from "../module/Module";
import ModuleBackground from "../module/ModuleBackground";
import ModuleContent from "../module/ModuleContent";
import ModuleLayer from "../module/ModuleLayer";
import TextLayer from "../module/layers/TextLayer";
import ImageLayer from "../module/layers/ImageLayer";
import VideoLayer from "../module/layers/VideoLayer";
import RichLayoutRow from "../richlayout/RichLayoutRow";
import RichLayoutColumn from "../richlayout/RichLayoutColumn";
import RichLayoutBody from "../richlayout/RichLayoutBody";
import Footer from "../Footer";
import ScrollDown from "../scrollDown/ScrollDown";
import { 
    localeData, 
} from "../../data/PageAssets";

export default class LocalePage extends BasePage
{
    constructor(props)
    {
        super(props);

        this._isLazy = true;                        // lazy loading 
        this._assetPath = "locale";                 // asset folder for page
        this._assets = localeData();                // asset data

        this.state = {
            hasLoaded: false,
            hasLazyLoaded: false
        }
    }

    componentDidMount()
    {
        super.componentDidMount();

        // start initial asset loading...
        this.startAssets({ 
            isLazy: false, 
            onAssetEnd: () => this.onAssetEnd() 
        });
    }

    onAssetEnd()
    {
        this.setState({ hasLoaded: true });
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        const { hasLoaded, hasLazyLoaded } = this.state;

        if (hasLoaded !== prevState.hasLoaded)
        {
            this.initPage();
            return;
        }

        if (hasLazyLoaded !== prevState.hasLazyLoaded)
        {
            this.afterPageLoad();
        }
    }

    render()
    {
        const { hasLoaded, hasLazyLoaded: lz } = this.state;
        const { hasResized: rz } = this.props;

        const getVid = this.pVideoNam("locale_loop");
        const mProps = {
            hasLazyLoaded: lz,
            hasResized: rz,
            device: this._devSize
        }
        
        const videoConfig = { 
            offset: 10, 
            position: "bottom center", 
            phone: { 
                offset: 20, 
                position: "bottom center" 
            } 
        }

        return (
            <Fragment>
                <article ref={div => this._pageRef = div} className={"page locale"}>
                    <div className={"page-content"}>
                        <h1>Locale | Positioned on the coast of the state of Oaxaca, at the edge of the Sierra Madre Mountains</h1>
                        <p>Huatulco covers 20 miles of Mexico’s pristine Pacific coastline, between the Coyula and Copalita rivers.  
                        The warm, limpid blue ocean waters bathe the shores of Huatulco’s 36 spectacular tropical beaches and 
                        nine unspoiled crystal-clear emerald bays with their countless inlets and coves, each with their own 
                        unique character and shimmering golden sands.</p>
                        <p>Only 50 minutes by air from Mexico City or a 2 to 4-hour flight from southern and central U.S., 
                        the area is one of the most unforgettable and relaxed destinations in Mexico.</p>
                        <p>The Bahias de Huatulco, is divided into districts. Tangolunda, home to the large 
                        fashionable resorts, Santa Cruz, with the main marina and the eponymous 
                        world-renowned beach and the town of La Crucecita, just inland from the 
                        beach area.</p>
                        <p>The Huatulco National Park, a federally protected wildlife and fauna sanctuary, 
                        encircles the bays of Bahia Maguey, Bahia Organo, Bahia Cacaluta, Bahía Chachacual 
                        and Bahía San Agustin. A must for snorkelers and divers, these bays offer the most 
                        important coral communities of the Mexican Pacific.</p>
                        <p>Over 700 species of animals thrive in the park, as well as numerous species of colorful fish. 
                        Despite being a protected zone, it is open to scuba diving, bird-watching and hiking.</p>
                        <p>Location and Points of Interest from Casa Lupita</p>
                        <ul>
                            <li>300 Meters to La Bocana Beach</li>
                            <li>6.9 KM to Tangolunda Bay, Resort District</li>
                            <li>10.4 KM Miles to Chedraui, (Grocery Store)</li>
                            <li>10.8 KM to La Crucecita, Town Centre (El Zocalo)</li>
                            <li>25.6 KM to Huatulco International Airport (HUX)</li>
                        </ul>
                    </div>    
                    {
                        hasLoaded && 
                        <div className="modules">
                    
                            <Module isHeader={true} isFullSize={true} {...mProps}>
                                <ModuleBackground 
                                    device={this._devSize}
                                    image={this.assetRef("locale_hero_shot")}
                                    video={{ desktop: getVid, delay: this._isPhone ? this._delay : 0 }}
                                />
                                <ModuleContent 
                                    textContentClass="text-align-center align-center v-bottom"
                                    isFullSize={true}
                                    heading="Locale"
                                    body={"Positioned on the coast of the state of Oaxaca, at the edge of the Sierra Madre Mountains"}
                                />
                                <div ref={this._scrollRef} className="module-scroll-down show-scroll"><ScrollDown /></div>
                            </Module>

                            <ModuleLayer backgroundColor="white" ignoreTopPadding={true} {...mProps}>

                                <ImageLayer 
                                    classes="first-pad"
                                    alt="Local map of Casa Lupita location"
                                    asset={this.assetRef("locale_map")}
                                    x={876} y={92} width={1030} height={485}
                                />
                                
                                <TextLayer classes="bottom-space" titleText={"Paradise on Earth"} x={340} y={89} width={464} height={370}>
                                    <p>Huatulco covers 20 miles of Mexico’s pristine Pacific coastline, between the Coyula and Copalita rivers.  
                                        The warm, limpid blue ocean waters bathe the shores of Huatulco’s 36 spectacular tropical beaches and 
                                        nine unspoiled crystal-clear emerald bays with their countless inlets and coves, each with their own 
                                        unique character and shimmering golden sands.</p>
                                </TextLayer>
                                
                            </ModuleLayer>
                            
                            <ModuleLayer backgroundColor="white" ignoreTopPadding={true} {...mProps}>

                                <ImageLayer 
                                    alt="Pirate Ships"
                                    asset={this.assetRef("locale_pirate_ships")}
                                    x={0} y={107} width={1053} height={703}
                                />
                                
                                <TextLayer classes="bottom-space" x={1154} y={120} width={673} height={207}>
                                    <p>A favorite haunt of pirates and British sea faring explorers like Sir Francis Drake, 
                                        local legend has it that under Spanish rule, pirates looted Huatulco, but that they 
                                        didn’t succeed in stealing its beauty.</p>
                                </TextLayer>
                                
                                <ImageLayer 
                                    alt="Sir Francis Drake portrait painting"
                                    asset={this.assetRef("locale_sir_francis")}
                                    x={1154} y={371} width={340} height={438}
                                />
                                
                                <ImageLayer 
                                    classes="vert-space"
                                    alt="Sir Francis Drake signature"
                                    asset={this.assetRef("locale_francis_signature")}
                                    x={1502} y={676} width={391} height={117}
                                />
                              
                            </ModuleLayer>
                            
                            <ModuleLayer backgroundColor="white" ignoreTopPadding={true} {...mProps} video={videoConfig}>

                                {/*<ImageLayer 
                                    alt="Top view of island"
                                    asset={this.assetRef("locale_top_view_shore")}
                                    x={736} y={107} width={1182} height={794}
                                />*/}

                                {<VideoLayer 
                                    hasLazyLoaded={lz}
                                    poster={this.assetRef("locale_top_view_shore").src()}
                                    asset={this.withPrefix("static/videos/minis/hd/locale_1036x794.mp4")}
                                    x={736} y={107} width={1182} height={794}
                                />}
                                
                                <TextLayer classes="bottom-space" x={158} y={80} width={480} height={825}>
                                    <>
                                    <p>Only 50 minutes by air from Mexico City or a 2 to 4-hour flight from southern and central U.S., 
                                        the area is one of the most unforgettable and relaxed destinations in Mexico.</p>
                                    <p>The Bahias de Huatulco, is divided into districts. Tangolunda, home to the large 
                                        fashionable resorts, Santa Cruz, with the main marina and the eponymous 
                                        world-renowned beach and the town of La Crucecita, just inland from the 
                                        beach area.</p>
                                    <p>The Huatulco National Park, a federally protected wildlife and fauna sanctuary, 
                                        encircles the bays of Bahia Maguey, Bahia Organo, Bahia Cacaluta, Bahía Chachacual 
                                        and Bahía San Agustin. A must for snorkelers and divers, these bays offer the most 
                                        important coral communities of the Mexican Pacific.</p>
                                    </>

                                </TextLayer>
                              
                            </ModuleLayer>
                            
                            <ModuleLayer backgroundColor="white" ignoreTopPadding={true} {...mProps}>

                                <ImageLayer 
                                    alt="Top view of island"
                                    asset={this.assetRef("locale_top_view_island")}
                                    x={0} y={107} width={1144} height={735}
                                />
                                
                                <TextLayer x={1254} y={80} width={548} height={210}>
                                    <>
                                    <p>Over 700 species of animals thrive in the park, as well as numerous species of colorful fish. 
                                        Despite being a protected zone, it is open to scuba diving, bird-watching and hiking.</p>
                                    </>

                                </TextLayer>
                                
                                <TextLayer
                                    classes="location-info bottom-space"
                                    titleHtml={"Location & Points of <div>Interest from Casa Lupita</div>"} 
                                    x={1254} y={375} width={630} height={540}>
                                    <ul>
                                        <li>300 Meters to La Bocana Beach</li>
                                        <li>6.9 KM to Tangolunda Bay, Resort District</li>
                                        <li>10.4 KM Miles to Chedraui, (Grocery Store)</li>
                                        <li>10.8 KM to La Crucecita, Town Centre (El Zocalo)</li>
                                        <li>25.6 KM to Huatulco International Airport (HUX)</li>
                                    </ul>
                                </TextLayer>
                              
                            </ModuleLayer>

                            <ModuleLayer 
                                backgroundColor="white" 
                                {...mProps}
                                ignoreTopPadding={true}
                                animate="push"
                            >
                                <ImageLayer 
                                    alt="Casa Lupita top view edge"
                                    asset={this.assetRef("locale_top_view_edge")}
                                    x={0} y={107} width={1920} height={1080}
                                />
                            </ModuleLayer>

                            <Module 
                                device={this._devSize}
                                hasRichLayout={true} 
                                classes="m-footer" 
                                backgroundColor="#0a0a0a" 
                                animate="footer"
                                hasLazyLoaded={lz}
                            >
                                <ModuleContent classes="full-width">
                                    <RichLayoutRow classes="">
                                        <RichLayoutColumn classes="flex-v-align-center">
                                            <RichLayoutBody>
                                                <Footer owner={this} pageRef={this._pageRef} hasLazyLoaded={lz} />
                                            </RichLayoutBody>
                                        </RichLayoutColumn> 
                                    </RichLayoutRow>
                                </ModuleContent>
                            </Module>

                        </div>
                    }

                </article>
            </Fragment>
        )
    }
}